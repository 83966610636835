function fullHeightConfig(){
	//Full Height Config
	var fullHeightContainers = $(".full-height-container");

	$.each(fullHeightContainers, function(index, container){
		var jQueryObject 		= 	$("#"+container.id);
		var jQueryObjectParent	=	jQueryObject.closest(".full-height-parent");


		var parentHeight 	= 	jQueryObjectParent.height();		
		var height 			=	jQueryObject.height();

		jQueryObject.css(
			{
				"height": "+="+(parentHeight - height)
			}
		);

	});

}

function getRandomColorRBG(){
	var random = Math.floor((Math.random() * 10) + 1);

	switch(random){
		case 1:
		case 2:
			return "e91e63";
			break;
		case 3:
		case 4:
			return "5677fc";
			break;			
		case 5:
		case 6:
			return "00bcd4";
			break;			
		case 7:
		case 8:
			return "259b24";
			break;			
		case 9:
		case 10:
			return "607d8b";
			break;						
	}

}

function getRandomColorClass(){
	var random = Math.floor((Math.random() * 10) + 1);

	switch(random){
		case 1:
		case 2:
			return "pink";
			break;
		case 3:
		case 4:
			return "blue";
			break;			
		case 5:
		case 6:
			return "cyan";
			break;			
		case 7:
		case 8:
			return "green";
			break;			
		case 9:
		case 10:
			return "grey";
			break;						
	}

}

$(document).ready(
	function(){
		fullHeightConfig();

		$('.datepicker').datepicker({
			format: 		'dd/mm/yyyy',
			language: 		'es',
			clearBtn: 		true,
			orientation:	'top',
			weekStart: 		1,
			autoclose:		true

		});

		if($('.dpValidateDesde').length && $('.dpValidateHasta').length) {
			// valida el input fecha desde en los listado de cotizacion y emision, tanto en auto como motos
			$('.dpValidateDesde').on('change', function(value) {
				var desde = moment($('.dpValidateDesde').datepicker("getDate"));
				var hasta = moment($('.dpValidateHasta').datepicker("getDate"));

				var diff = hasta.diff(desde, 'days', true);

				// la diferencia no puede ser mayor a 3 meses porque es mucha carga para el server 
				// uso dias para comparar, si uso meses puede romperse si es uno de los ultimos dias del mes
				if(diff > 93) {
					$('.dpValidateHasta').datepicker('setDate', desde.add(93, 'days').format('DD/MM/YYYY'));
				} else if(diff < 0) {
					// si setea una fecha mayor a la fechaHasta => reinicio la fechaHasta
					$('.dpValidateHasta').datepicker('setDate', null);
				}
			});

			// valida el input fecha hasta en los listado de cotizacion y emision, tanto en auto como motos
			$('.dpValidateHasta').on('change', function(value) {
				var desde = moment($('.dpValidateDesde').datepicker("getDate"));
				var hasta = moment($('.dpValidateHasta').datepicker("getDate"));

				var diff = hasta.diff(desde, 'days', true);
				
				// lo mismo que el validate de fechaDesde
				if(diff > 93) {
					$('.dpValidateDesde').datepicker('setDate', hasta.subtract(93, 'days').format('DD/MM/YYYY'));
				} else if(diff < 0) {
					// si setea una fecha menor a la fechaDesde => reinicio la fechaDesde
					$('.dpValidateDesde').datepicker('setDate', null);
				}
			});
		}

		ToastrSetup();
	}
);

function checkEnableSubmit(){
	var enable = true;
	var selects = $("select");
	
	for(var index = 0; index < selects.length; index++){

		if(selects[index].children.length == 0){
			enable = false;
		} else if(selects[index].children.length == 1){
			if(selects[index].children[0].value == 0){
				enable = false;
			}
		}

	}

	if(!enable){
		$("#submit").attr("disabled", true);
	} else {
		$("#submit").attr("disabled", false);
	}

}


function eventStatusChat(){
	var chat = $("#chatContainer");
	var image = chat.find(".chat-heading .fa");

	if(chat.hasClass("not-displayed")){
		chat.removeClass("not-displayed");
		image.addClass("fa-times");
		image.removeClass("fa-chevron-up");		
	} else {
		chat.addClass("not-displayed");
		image.removeClass("fa-times");
		image.addClass("fa-chevron-up");
	}
}

/**
 * TODO - TOAST SETUP (ALERTAS)
 * */
function ToastrSetup(){
	var options = {
		closeButton: true,
		debug: false,
		newestOnTop: true,
		progressBar: false,
		positionClass: "toast-top-right",
		preventDuplicates: false,
		showDuration: "300",
		hideDuration: "1000",
		timeOut: "5000",
		extendedTimeOut: "1000",
		showEasing: "swing",
		hideEasing: "linear",
		showMethod: "fadeIn",
		hideMethod: "fadeOut" }
	toastr.options = options;
}

/*
 * METODOS AGREGADOS MAPPLICS
 * -----------------------
 * -----------------------
 *
 * TODO => LOADING
 *
 * @type plugin
 * @usage $("#box-widget").loading( options );
 */
(function($) {
	$.loading = function(options) {
		// Si no esta definido o es true, lo prendo
		if(typeof options==='undefined' || (typeof options==='boolean'&&options===true ) )
			options = { 'overlay': '', 'loading_img': '', 'on_off':'on' };
		// Si no es objeto o es false, lo apago
		if(typeof options!=='object'    || (typeof options==='boolean'&&options===false) )
			options = { 'overlay': '', 'loading_img': '', 'on_off':'off' };
		// Overlay por defecto
		if(options.overlay!=='' && options.overlay!=='dark')
			options.overlay='';
		// off por defecto, en caso de que no sea ni on ni off
		if(options.on_off!=='on' && options.on_off!=='off')
			options.on_off='off';

		if(options.on_off=="on") {
			// Si ya tenia overlay lo saco
			if( $('body').children('.overlay').length > 0 )
				return;
			// Calculo el height del body y del window (para la img)
			var body_height = $('body').height();
			var win_height = $(window).height();
			var style = "style='height: "+body_height+"px; ";
			// Para la imagen, veo si tiene otro archivo de imagen, sino dejo el por defecto (seteado en el css)
			var style_img = "style='height: "+win_height+"px; ";

			if(options.loading_img!=='')
				style_img+= ";background: transparent url('../img/"+options.loading_img+"') 50% 50% no-repeat;";

			style+="'";
			style_img+="'";

			var str = '<div class="overlay '+options.overlay+'" '+style+' ></div><div class="loading-img" '+style_img+'></div>';
			// Lo añado al body
			$('body').append( str );
		}
		else {
			// Los elimino
			$('body').children('.overlay').remove();
			$('body').children('.loading-img').remove();
		}
	}
}(jQuery));